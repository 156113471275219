@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/avenir/AvenirNextBold01.eot');
  src: url('/assets/fonts/avenir/AvenirNextBold01.eot') format('embedded-opentype'),
  url('/assets/fonts/avenir/AvenirNextBold01.woff2') format('woff2'),
  url('/assets/fonts/avenir/AvenirNextBold01.woff') format('woff'),
  url('/assets/fonts/avenir/AvenirNextBold01.ttf') format('truetype'),
  url('/assets/fonts/avenir/AvenirNextBold01.svg#AvenirNextBold01') format('svg');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/avenir/AvenirNextBoldItalic02.eot');
  src: url('/assets/fonts/avenir/AvenirNextBoldItalic02.eot') format('embedded-opentype'),
  url('/assets/fonts/avenir/AvenirNextBoldItalic02.woff2') format('woff2'),
  url('/assets/fonts/avenir/AvenirNextBoldItalic02.woff') format('woff'),
  url('/assets/fonts/avenir/AvenirNextBoldItalic02.ttf') format('truetype'),
  url('/assets/fonts/avenir/AvenirNextBoldItalic02.svg#AvenirNextBoldItalic02') format('svg');
  font-weight: bold;
  font-style: italic;
}


@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/avenir/AvenirNextItalic05.eot');
  src: url('/assets/fonts/avenir/AvenirNextItalic05.eot') format('embedded-opentype'),
  url('/assets/fonts/avenir/AvenirNextItalic05.woff2') format('woff2'),
  url('/assets/fonts/avenir/AvenirNextItalic05.woff') format('woff'),
  url('/assets/fonts/avenir/AvenirNextItalic05.ttf') format('truetype'),
  url('/assets/fonts/avenir/AvenirNextItalic05.svg#AvenirNextItalic05') format('svg');
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/avenir/AvenirNextRegular08.eot');
  src: url('/assets/fonts/avenir/AvenirNextRegular08.eot') format('embedded-opentype'),
  url('/assets/fonts/avenir/AvenirNextRegular08.woff2') format('woff2'),
  url('/assets/fonts/avenir/AvenirNextRegular08.woff') format('woff'),
  url('/assets/fonts/avenir/AvenirNextRegular08.ttf') format('truetype'),
  url('/assets/fonts/avenir/AvenirNextRegular08.svg#AvenirNextRegular08') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?igg7va');
  src:  url('/assets/fonts/icomoon.eot?igg7va#iefix') format('embedded-opentype'),
  url('/assets/fonts/icomoon.ttf?igg7va') format('truetype'),
  url('/assets/fonts/icomoon.woff?igg7va') format('woff'),
  url('/assets/fonts/icomoon.svg?igg7va#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-left:before {
  content: "\e900";
}
.icon-right:before {
  content: "\e901";
}
.icon-apps:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-linkedin:before {
  content: "\eac9";
}
.icon-pinterest:before {
  content: "\ead1";
}

