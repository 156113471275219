.main-footer {
	text-align: center;
	padding: 15px;
	.align-self(flex-end);
}

.social {
	display: block;
	margin: 0 -7.5px;
	&__item {
		display: inline-block;
		padding: 0 7.5px;
	}
}

.icon {
/*
	width: 35px;
	height: 35px;
*/
	display: block;
	font-size: 36px;
/*
	background: no-repeat center center;
	background-size: 100% auto;
*/
	.transition(transform .1s linear);
	&:hover {
		.transform(scale(1.2));
	}
/*
	
	&--facebook {
		background-image: url('/assets/img/icon-social-facebook.svg');
	}
	&--instagram {
		background-image: url('/assets/img/icon-social-instagram.svg');
	}
	&--pinterest {
		background-image: url('/assets/img/icon-social-pinterest.svg');
	}
*/
}