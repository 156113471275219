.home {
	.main-footer {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		text-align: center;
		z-index: 10;
	}
}



.slogan {
	padding-left: 15px;
	padding-right: 15px;
	color: #fff;
	text-align: center;
	font-size: 24px;
	z-index: 10;
	font-weight: bold;
	letter-spacing: -0.05em;

	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;

	text-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);

	&__logo {
		width: auto;
		height: 17px;
	}

	& a {
		color:@linkcolor;

		&:hover{
			text-decoration: underline;
			color:inherit;
		}
	}
}

.splash {
	position: relative;
	width: 100%;
	height: 100vh;
}

.work {
	
}

.fullscreen-image-container {
	width: 100%;
	text-align: center;
	padding-top: 80px;

	&__image {
		width: 90%;
		margin: 0 auto;
	}
}

@media screen and (min-width: 622px) {
	.fullscreen-image-container {

		&__image {
			width: 95%;
		}
	}
}

@media screen and (min-width: 768px) {

	.fullscreen-image-container {
		width: 100%;
		height: 100%;
		padding-top: 0;
		background-image: url(/assets/img/bg.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		&__image {
			display: none;
		}
	}
	
	.slogan {
		font-size: 40px;
		position: absolute;
		top: 50%;
		left:50%;
		.transform(translate(-50%,-50%));
		max-width:800px;
		
		&__logo {
			height: 34px;
		}
	}
	
}