// classes
.clear,.clearfix {
	display: block;
	clear: both;
}
.del {
	text-decoration: line-through;
}
.nowrap {
	white-space: nowrap;
}
.nodec {
	text-decoration: none;
}
.bold { 
	font-weight: bold; 
}
.border {
	border: 1px solid @bordercolor !important;
}
.border-none {
	border:none !important;
}
.border-t { 
	border-top:1px solid @bordercolor !important; 
}
.border-l { 
	border-left:1px solid @bordercolor !important; 
}
.border-r { 
	border-right:1px solid @bordercolor !important; 
}
.border-b { 
	border-bottom:1px solid @bordercolor !important; 
}
.border-lr { 
	border-left:1px solid @bordercolor !important; 
	border-right:1px solid @bordercolor !important;
}
.border-tb { 
	border-top:1px solid @bordercolor  !important;
	border-bottom:1px solid @bordercolor !important;
}