// Fullscreen Video

.fullscreen-video-container {
	overflow: hidden;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	.disable-hover {

	}
	iframe, .disable-hover {
		z-index: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.disable-hover {
		z-index: 1;
	}
}

// Video controls

.video-control {
	position: fixed; 
	bottom: 0; 
	left: 0; 
	width: 100%; 
	text-align: center; 
	z-index: 101;
	padding: 20px 15px;
	
	&__button {
		width: 40px;
		height: 40px;
		padding: 0;
		text-align: center;
		i {
			margin: -2px 0 0 0;
			display: inline-block;
		}
		&--is-inactive {
			display: none;
		}
	}
}