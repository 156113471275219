body, html {
	font-family: "Avenir", 'Helvetica Neue', Helvetica, Arial, sans-serif;
	background-color: #000;
	color: #fff;
	//text-transform: lowercase;
	overflow-x:hidden;
}

body {
		
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	
	-webkit-flex-direction:  row-reverse;
	-ms-flex-direction:  row-reverse;
	flex-direction: row-reverse;
	
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	
	min-height: 100vh;
	
	> * {
		width: 100%;
	}
}

address {
	font-style: normal;
}

a, .link {
	color: inherit;
	text-decoration: none;
	.transition(color .2s linear);
	
	&:hover {
		color: @linkcolor;
	}
}


.link--has-underline {
	white-space: nowrap;
	text-decoration: underline;
}

.link--cta {
	color: @linkcolor;
	}

figure {
	margin: 0
}

.main-content {
	padding-top: 80px;
	&--fullscreen {
		padding-top: 0;
	}
}

.container {
	position: relative;
	margin: 0 15px;
}

@media screen and (min-width: 768px) {
	.main-content {
		padding-top: 120px;
		&--fullscreen {
			padding-top: 0;
		}
	}
}

@media screen and (min-width: 992px) {
	.main-content {
		padding-top: 200px;
		&--fullscreen {
			padding-top: 0;
		}
	}
	
	.container {
		margin: 0 180px;
	}
}

@media screen and (min-width: 1200px) {
	
}