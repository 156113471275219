.work {
	margin: 30px 0;
}

.projects {

	&::after {
		clear: both;
		content: '';
		display: table;
		width: 100%;
	}
	&__item {
		float: left;
		margin-bottom: 25px;
		padding:0;
		width: 100%;
	}
}

.project {
	&__thumbnail {
		overflow: hidden;
		position: relative;
		padding-top:56.25%;
	}
	&__visual {
		display: block;
		height:auto;
		min-height: 100%;
		width:100%;
		position: absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
	}
	&__title {
		font-size: 1.25em;
		margin-top: 10px;
	}

	&-detail {
		padding-top:130px;
	}

}

.lazyload {
	&.lazyload--zoomed {
		opacity: 0;
	}
}

.project-detail {
	&__row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom:50px;

		&::after {
			clear: both;
			content: '';
			display: table;
			width: 100%;
		}
	}
	&__col {
		float: left;
		margin-bottom: 25px;
		padding: 0 12.5px;
		width: 100%;
	}
	&__visual {
		display: block;
		height: auto;
		width: 100%;
	}
	&__title {
		font-size: 1.3em;
		font-weight: bold;
		margin: 0 0 10px 0;
		line-height:1.1em;
		display: block;
		width:100%;
	}
	&__address {
		font-size: 1em;
		display: block;
		width:100%;
	}
	&__credits {
		font-size: 1em;
		font-style: italic;
		display: block;
		width:100%;
	}


	&__navigation {
		display: block;
		width:100%;
		text-align: right;
		font-size:15px;
		margin:-30px 0 20px;
		position: absolute;
		right:0;
		z-index: 99;

		.button--back {
			background:transparent;
		}

		.button--back:hover {
			color:@linkcolor;
			}
	}
}

.projectlist {
	margin:35px 0 0;
	padding:0 20px;

	&__category {
		display: block;
	}

	&__title {
		cursor:pointer;
		font-size:25px;
		transition: color 0.3s ease;

		&:hover {
			color:@linkcolor;
		}
	}

	&__title[aria-expanded="true"] {
		color: @linkcolor;
	}

	&__projects {
		padding:20px 0;

		&:after {
			content:'';
			display: block;
			clear:both;
		}
	}
}


@media screen and (min-width: 768px) {
	
	.work {
		margin: 60px 0;
	}

	.projects {
		&__item {
			width: ~"calc(50% - 15px)";
		}

		&__item:nth-child(even) {
			margin-left:25px;
		}
	}

	.project-detail {
		&__col {
			width: 50%;
		}
	}

	.projectlist {

		&__title {
			font-size:40px;
		}
	}
	
}

@media screen and (min-width: 992px) {

	.project {
		&-detail {
			padding-top:200px;
		}
	}

	.project-detail {
		&__navigation {
			width:50%;
			}
		}

	
}
