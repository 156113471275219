/* form elements */
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
textarea,
textarea.form-control {
	//-webkit-appearance: none;
	padding: 10px;
	border-radius: 0;
	border: 1px solid @bordercolor;
	resize: none;
	font-family: inherit;
	font-size:inherit;
}

input[type="submit"], input[type="reset"], input[type="button"], button {
	cursor: pointer;
	-webkit-appearance: none;
	border-radius: 0;
	border: none;
}

/* Remove those stupid arrows from number input  */
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}
input[type=number] {
    -moz-appearance:textfield;
}

/* Create a standard for Placeholders */
::-webkit-input-placeholder {
   color: #666;
}

:-moz-placeholder { /* Firefox 18- */
   color: #666;  
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #666;  
}

:-ms-input-placeholder {  
   color: #666;  
}

.form-container {
  .row {
    margin: -5px;
    [class*=' col-'], [class^='col-'] {
      padding: 5px;
      input, textarea, select {
        width: 100%;
      }
    }
  }
}

.form {
  background:#000;
  position: relative;
  z-index: 1;

  &__row {
    margin-bottom: 5px;
    display: block;
    width:100%;
    clear:both;
    position: relative;
  }

  &__col {
    position: relative;
    width:100%;
    float:left;
  }

  &__label {
    display: block;
    font-weight: 300;
  }

  .required::after {
    content: '';
  }
  .text-danger{
    display: none;
  }

  &__input {
    margin:0 0 10px 0;
    height:45px;
    padding:0 10px;
    border:none;
    display: block;
    width:100%;
  }
  
  &__input[type="checkbox"] {
    display: inline-block;
    width:30px;
    height:30px;
    position: absolute;
    z-index: 2;
    top:0;
    left:0;
    opacity:0;
  }

  &__input[type="checkbox"] + .checkbox__legend::before {
    position: absolute;
    content:'';
    display:block;
    width:25px;
    height:25px;
    border:3px solid #444;
    left:1px;
    z-index: 1;
    box-shadow: 0 0 0 1px rgba(255,255,255,0.6);
    background:#444;
  }

  &__input[type="checkbox"]:checked + .checkbox__legend::before {
    position: absolute;
    content:'';
    display:block;
    width:25px;
    height:25px;
    border:5px solid #444;
    left:1px;
    z-index: 1;
    box-shadow: 0 0 0 1px rgba(255,255,255,0.6);
    background:@linkcolor;
  }

  .checkbox__legend {
    display: block;
    padding-left:50px;
    font-size:16px;
  }

  &__textarea {
    padding:10px;
    border:none;
    min-height:150px;
    width:100%;
  }

  &__label--as-input {
    cursor:pointer;
  }

  &__input--as-input {
    position: absolute;
    visibility: hidden;
    opacity:0;
    left:0;
  }

  &__input:focus + .form__label,
  &__input:hover + .form__label  {

  }

  &__input + &__label--as-input::before,
  [type="checkbox"] + .form__label--as-input::before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    background-color: white;
    padding: .35em;
    border: solid 0.2em white;
    margin-right: .5em;
    box-shadow: 0 0 0 2px grey;
  }

  &__input:checked + .form__label--as-input::before,
  [type="checkbox"]:checked + .form__label--as-input::before{
    background-color: orange;
  }

  &__input:checked + .form__label--as-input,
  [type="checkbox"]:checked + .form__label--as-input {
    color: orange;
  }

  &__btn {
    display: inline-block;
    border:none;
    position: relative;
    cursor:pointer;
    padding:11px 15px;
    margin-top:20px;
    background:@linkcolor;
    font-size:16px;
    margin-bottom:30px;
    transition: background-color 0.6s ease;

    &:hover {
      background:darken(@linkcolor, 15%);
    }
  }

  .btn--submit {
    float:right;
    width:100%;
    max-width:260px;
  }

  [type="checkbox"] + .form__label--as-input::before {
    border-radius: 0;
  }

  &__rowcontainer {
    display: none;
  }

  &__rowcontainer.is-visible {
    display: block;
    margin-bottom:30px;
  }

  .form__row--checkbox {
    margin-top:30px;
  }

}

.form__row {

  &:after {
    display: table;
    clear:both;
    content:'';
  }
}

.form__field-fill {
  position: absolute;
  width:100%;
  height:1px;
  overflow:hidden;
  z-index: -1;
  opacity: 0;
  outline:0;

  .form__input:focus {
    opacity: 0;
    outline:0;
    }
  }

.grecaptcha-badge {
  display:none;
}



@media screen and (min-width: 500px) {
  .form {

    &__col {
      width: ~"calc(50% - 5px)";
    }
  }

}

@media screen and (min-width: 768px) {
  .form {

    &__btn {
      font-size:18px;
      margin-bottom:0;
    }
  }
}

@media screen and (min-width: 992px) {
  .form {

    &__btn {
      font-size:20px;
    }
  }
}