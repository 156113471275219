.page {

  &__title {
    margin-bottom: 0;
    font-size: 1.6em;
    font-weight: bold;

    &--small {
      font-size: 2em;
    }
  }

  &__about:after {
    display: table;
    content: '';
    clear: both;
  }
}

.about--col {

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.about {

  &__images {
    margin-top: 35px;
  }

  &--img {
    margin-bottom: 20px;
    width: 100%;
    max-width: 500px;
  }

  &--img > * {
    width: 100%;
    max-width: 400px;
  }
}



@media screen and (min-width: 768px) {

  .about {

    &__content {
      width: 50%;
      padding: 0 20px;
      float: left;
    }

    &__images {
      width: 50%;
      max-width: 500px;
      padding: 0 20px;
      float: left;
    }
  }
}