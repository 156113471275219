.tns-outer {
    position: relative;
    margin: 20px 0;
    background:rgba(255,255,255,0.1);

    .tns-controls button[type="button"] {
        color:black;
        position: absolute;
        cursor: pointer;
        z-index: 2;
        top:50%;
        transform:translateY(-50%);
        height:70px;
        width:50px;
        text-indent:-99999px;
        background:transparent;

        &:before {
            content:'';
            position: absolute;
            width:100%;
            height:100%;
            left:0;
            top:0;
            text-indent:0;
            color:white;
            padding-top:15px;
            font-size:40px;
        }

        &[data-controls="next"] {
            right: 0;

            &:before {
                font-family: 'icomoon';
                content:'\e901';
            }
        }

        &[data-controls="prev"] {
            left: 0;

            &:before {
                font-family: 'icomoon';
                content:'\e900';
            }
        }
    }

    .tns-controls {
        outline: 0;
    }

    .tns-nav {
        position: absolute;
        z-index: 2;
        bottom:10px;
        left:50%;
        transform:translateX(-50%);

        button[aria-selected] {
            width:14px;
            height:14px;
            padding:0;
            border-radius:50%;
            margin:0 4px;
            background:rgba(255,255,255,0.7);
        }

        button[aria-selected="true"] {
            background: @linkcolor;
        }
    }

}

.tns-inner {
    position: relative;
    z-index: 1;
}

.slideshow__item {

    &--imgcontainer {
        display: block;
        width:100%;
        padding-top:56.25%;
        position: relative;
    }

    &--visual {
        display: block;
        height:100%;
        position: absolute;
        top:0;
        left:50%;
        transform:translateX(-50%);
    }
}

