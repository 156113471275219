@import url('_forms.less');

.contact, .page--contact {
	position: relative;
	margin-bottom: 30px;
	&:after {
		clear: both;
		display: table;
		content: '';
	}
	.link {
		font-size: 24px;
	}
	.link--cta {
		font-size:16px;
		}
	.visual {
		max-width: 500px;
		margin: 0 auto;
		> * {
			max-width: 100%;
			height: auto;
		}
	}
}

.contact {
	margin: 30px 0;
}



.form__input,
.form__textarea {
	background:rgba(255,255,255,0.2);
	border:1px solid rgba(255,255,255,0.3);
}


@media screen and (min-width: 500px) {

	.form__row {
		margin-top: 20px;

		.form__col:last-of-type {
			float: right;
		}
	}

	.form__label {
		padding-bottom: 5px;
		font-size: 16px;
	}

}


@media screen and (min-width: 768px) {
	
	.contact, .page--contact {
		.link {
			font-size: 48px;
		}
		.address {
			font-size: 18px;
		}

		.link--cta {
			font-size:18px;
			}
	}



	.page--contact {

		&:after {
			display: block;
			clear:both;
			content:'';
		}
	}

	.contact {
		margin: 60px 0;

		&--form {
			float:left;
			display: block;
			width:~"calc(100% - 400px)";
			max-width:500px;
			min-width:275px;
		}

		&--data {
			float:right;
			min-width:320px;
		}
	}

	.form__label {
		font-size: 18px;
	}
}

@media screen and (min-width: 992px) {
	
	.contact, .page--contact {
		.contact-data {
			width: 100%;
			position: absolute;
			top: 50%;
			.transform(translateY(-50%));
		}
		.address {
			font-size: 17px;
		}
		
		.visual {
			float: right;
		}
	}
	.contact {
		margin-left: 200px;
		margin-right: 200px;
	}

	.form__label {
		font-size: 20px;
	}
	
}


@media screen and (min-width: 1400px) {
	
	.contact, .page--contact {

		.link--cta {
			font-size:18px;
			}
	}
	
}