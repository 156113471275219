.main-header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 20;
	padding: 15px;
	
	&__logo {
		float: left;
		max-width: 140px;
		&__visual {
			width: 100%;
			height: auto;
			display: block;
		}
	}
	
	&__menu {
		padding: 0;
		float: right;
		text-align: right;
	}
}

.hamburger {
	cursor: pointer;
	&__label {
		text-transform: uppercase;
		color: @linkcolor;
		vertical-align: middle;
		font-size: 18px;
		opacity: 1;
		.transition(opacity .2s linear);
		&--hidden {
			opacity: 0;
		}
	}
	&:hover {
		.hamburger__icon {
			background-image: url('/assets/img/bg-menu.gif');
/*
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: #000 url('/assets/img/bg-menu.png') no-repeat center center;
				background-size:  100% 100%;
				display: block;
				content: '';
				animation-name: rotateMenu;
				animation-duration: 1s;
				animation-iteration-count: infinite;
			}
*/
			.tline {
				.transform(translateY(-2px));
			}
			.bline {
				.transform(translateY(2px));
			}
		}
	}
	&__icon {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		overflow: visible;
		width: 50px;
		height: 50px;
 		padding: 6px 10px;
 		background: #000 url('/assets/img/bg-menu.png') no-repeat center center;
 		background-size:  100% 100%;

		&.active {
			.tline {
				.transform(rotate(-45deg) translate3D(-5.5px, 5.5px, 0));
			}
			.mline {
				opacity: 0;
			}
			.bline {
				.transform(rotate(45deg) translate3D(-5.5px, -5.5px, 0));
			}
		}
		.line {
			z-index: 10;
			position: relative;
			margin: 6px 0;
			height: 4px;
			border-radius: 3px;
			background-color: @linkcolor;
			display: block;
			.transition(transform .1s linear); // activating this creates a glitch in Chrome
		}
	}
}

.pop-up-menu {
// 	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	padding: 5px;
	z-index: 9999;
	text-align: center;
	
	visibility: hidden;
	opacity: 0;
	.transform(scale(0.5));
	.transition(all .2s ease-out);
	&--is-opened {
// 		display: block;
		visibility: visible;
		opacity: 1;
		.transform(scale(1));
	}
	&__container {
		position: relative;
		padding: 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		height: 100%;
		background: url('/assets/img/bg-menu-popup.jpg') no-repeat center center;
		background-size: cover;

		> * {
			width: 100%;
		}
	}
	&__trigger {
		display: block;
		position: absolute;
		top: 10px;
		right: 10px;
		border: none;
		height: 50px;
		width: 50px;
		background: url('/assets/img/icon-menu-close.svg') no-repeat center center;
		background-size: 30px auto;
		.transition(transform .1s linear);
		&:hover {
			.transform(scale(1.2));
		}
	}
	&__nav {
		width: 100%;
		left: 0;
		position: absolute;
		top: 35%;
		.transform(translateY(-50%));
	}
	&__footer {
		align-self: flex-end;
	}
	
	.icon {
		width: 25px;
		height: 25px;
	}
	
	.menu {
// 		font-weight: bold;
		font-size: 24px;
		letter-spacing: -0.05em;
		&__item {
			display: block;
			margin: 20px 0;
		}
	}
	
}

.address {
	font-size: 16px;
// 	font-size: 24px;
	&__item {
// 		margin: 30px auto;
		margin: 15px auto;
	}
	&__name {
		display: block;
 		font-weight: bold;
	}
	&__name--underlined {
		text-decoration: underline;
		font-weight: normal;
	}
	&__location,
	&__email,
	&__phone {
		display: block;
	}
}

@media screen and (min-height: 640px) {
	
	.pop-up-menu {
		&__nav {
			top: 50%;
		}
	}
}

@media screen and (min-width: 768px) {

	.main-header {
		&__logo {
			&__visual {
				height: 65px;
				display: block;
				margin: 10px 0 0;
			}
		}
	}
	
	.hamburger {
		&__label {
			font-size: 30px;
		}
		&__icon {
			width: 85px;
			height: 85px;
			padding: 22px 25px;
			
			.line {
				margin: 6px 0;
				height: 6px;
			}
		}
	}
	
	.pop-up-menu {
		padding: 15px;
		
		&__container { 
			padding: 15px;
		}
		&__trigger {
			top: 0;
			right: 0;
			width: 85px;
			height: 85px;
			background-size: 35px auto;
		}
		
		.menu {
			font-size: 48px;
			&__item {
				display: inline-block;
				margin: 20px;
			}
		}
	}
	
}

@media screen and (min-width: 992px) {
	
	.main-header {
		padding: 70px 30px 0;

		&__logo {
			&__visual {
				width:auto;
			}
		}
	}

	
	.pop-up-menu { 
		padding: 30px;
		
		.icon {
			width: 35px;
			height: 35px;
		}
		
		&__trigger {
			top: 40px;
		}
	}
	
	.address {
		font-size: 24px;
		&__item {
			margin: 30px auto;
		}
	}
	
}

@media screen and (min-width: 1200px) { 
	
	.pop-up-menu { 
		padding: 30px;
		&__nav { 
			
		}
		
		.menu {
			font-size: 75px;
		}
		
	}
}